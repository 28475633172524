import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

/**
 * The root component of the application.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  message = 'You are being redirected...';

  /**
   * Constructs an instance of the AppComponent with the specified dependencies.
   * 
   * @param _activatedRoute - The ActivatedRoute for accessing the current route information.
   * @param _cookieService - The CookieService for managing cookies.
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    @Inject('WINDOW') private window: Window
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._activatedRoute.queryParams
      .subscribe((params: Params) => {
        if (params && Object.keys(params).length > 0) {
          this._redirect(params);
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Redirects the user to the specified URL.
   * 
   * @param params - The query parameters.
   */
  private _redirect(params: Params): void {

    console.log("params", params);
    
    if ('continueUrl' in params) {
      /**
       * Coming from Firebase:
       * Ex: Forgotten email, password reset, etc.
       */
      // 

      console.log("continueUrl found");

      // Fetch the redirection URL
      const continueUrl: URL = new URL(params['continueUrl']);
      console.log("continueUrl", continueUrl);

      // Append the query parameters
      Object.keys(params).forEach(key => {
        if (key !== 'continueUrl') {
          continueUrl.searchParams.append(key, params[key]);
        }
      });
      console.log("continueUrl", continueUrl);

      // Remove the redirection cookie, if any
      if (this._cookieService.get('redirectHostname')) {

        console.log("removing cookie");
        this._cookieService.delete('redirectHostname', '/', 'qart.shop', true, 'Lax');
      }

      console.log("redirection");
      // Redirect to the URL
      console.log(continueUrl);
      this.window.location.replace(continueUrl);

    } else if (Object.keys(params).includes('state')) {
      /**
       * Coming from Google connect, Stripe connect
       */
      
      const queryParams = JSON.parse(atob(params['state']));
      console.log("queryParams", queryParams);

      // Fetch the redirection URL
      const continueUrl: URL = new URL(queryParams['continueUrl']);

      // Append the query parameters (except the state)
      Object.keys(params).forEach(key => {
        if (key !== 'state') {
          continueUrl.searchParams.append(key, params[key]);
        }
      });
      // Append the query parameters (except the continueUrl)
      Object.keys(queryParams).forEach(key => {
        if (key !== 'continueUrl') {
          continueUrl.searchParams.append(key, queryParams[key]);
        }
      });
      console.log("continueUrl", continueUrl);

      // Remove the redirection cookie, if any
      if (this._cookieService.get('redirectHostname')) {

        console.log("removing cookie");
        this._cookieService.delete('redirectHostname', '/', 'qart.shop', true, 'Lax');
      }

      console.log("redirection");
      // Redirect to the URL
      console.log(continueUrl);
      this.window.location.replace(continueUrl);

    } else if (this._cookieService.get('redirectHostname')) {

      console.log("continueUrl not found but cookie found");

      // Fetch the redirection hostname
      const redirectHostname: string = this._cookieService.get('redirectHostname');      

      console.log("redirectHostname", redirectHostname);

      // Remove the redirection cookie
      this._cookieService.delete('redirectHostname', '/', 'qart.shop', true, 'Lax');

      // Replace the hostname in the current URL
      const redirectUrl: URL = new URL(location.href);
      console.log("redirectUrl", redirectUrl);
      redirectUrl.hostname = redirectHostname;
      console.log("redirectUrl", redirectUrl);

      // Redirect to the URL
      this.window.location.replace(redirectUrl);

    }

    // http://localhost:4200/?mode=verifyEmail&oobCode=VxCSRGfWvLgbgpYj_-D7nv98MB2KgL9WfSJKss58xeUAAAGCjPZiHw&apiKey=AIzaSyBZVYs1FtUbWXiDkDUEsOveot_EhTxjsI4&continueUrl=https%3A%2F%2Fmon.qart.shop%2Fredirect&lang=en
    // http://localhost:4200/redirect?mode=verifyEmail&oobCode=VxCSRGfWvLgbgpYj_-D7nv98MB2KgL9WfSJKss58xeUAAAGCjPZiHw&apiKey=AIzaSyBZVYs1FtUbWXiDkDUEsOveot_EhTxjsI4&continueUrl=https%3A%2F%2Fmon.qart.shop%2Fredirect&lang=en


    /*
    if (this._cookieService.get('redirectHostname')) {
      let url: string = this._cookieService.get('redirectHostname');
    } else if (params) {
      console.log(params['continueUrl'])
      const url: URL = new URL(params['continueUrl']);
      console.log(url);
    } 
    */
    
    /*
    //url = 'https://dev.my.qart.shop';
    if (url) {
      
      // Remove the redirection hostname from the local storage
      //this._cookieService.delete('redirectHostname', '/', 'qart.shop', true, 'Lax');
      const currentUrl: string = location.href;
      const redirectUrl: URL = new URL(currentUrl);
      
      //redirectUrl.hostname = redirectUrl;
      location.replace(redirectUrl);
    } else if (params['continueUrl']) {

      this.message = 'No redirection hostname found.';
    }
    */
  }

}
